@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --foundation-blue-normal: #152835;
  --ivymode: "ivymode", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --foundation-yellow-normal: #EECF8A;
  --foundation-grey-light:#f7f7f7;
  --foundation-white-light:#fff;
  --foundation-yellow-light: #FDFAF3;
  --foundation-yellow-normal-hover: #D6BA7C;
  --foundation-dark: #000;
  --foundation-dark-blue: #0D1820;
}

.textify .word,
.textify .char {
  display: inline-block;
  will-change: transform, opacity;
}

.textify .char {
  position: relative;
}

.textify .char::before,
.textify .char::after,
.textify .word::after,
.textify .word::before,
.textify .line::after,
.textify .line::before {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  display: none;
}

.textify .char::before,
.textify .char::after {
  content: attr(data-char);
}

.textify .word::after,
.textify .word::before {
  content: attr(data-word);
}

.textify .line::after,
.textify .line::before {
  content: attr(data-line);
}

.textify .line-box,
.textify .line {
  overflow: hidden;
}

body {
  overflow-x: hidden;
}

header {
  padding-top: 76px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;
  transition: all 0.5s ease-out;
}
header .logo {
  display: block;
  transition: all 0.5s ease-out;
}
header .icon {
  display: none;
  transition: all 0.5s ease-out;
}
header ul {
  gap: 44px;
}
header ul li a {
  gap: 12px;
  color: var(--foundation-yellow-normal);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
}

header.active {
  background: var(--foundation-white-light);
  padding: 20px 0;
  transition: all 0.5s ease-out;
}
header.active .logo {
  display: none;
  transition: all 0.5s ease-out;
}
header.active .icon {
  display: block;
  transition: all 0.5s ease-out;
}

body.active {
  overflow: hidden;
}
body.active header.active::before {
  content: "";
  position: absolute;
  width: 41.5%;
  height: 100%;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--foundation-blue-normal);
}
body.active header.active .logo {
  display: block;
}
body.active header.active .icon {
  display: none;
}

.button_container {
  position: relative;
  width: 30px;
  height: 25px;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  margin-left: auto;
}

.button_container span {
  width: 30px;
  height: 2px;
  position: absolute;
  background: var(--foundation-yellow-normal);
  transition: all 0.35s ease;
}

.masonry-container {
  column-count: 3;
  column-gap: 40px;
}

.masonry-column {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  break-inside: avoid;
}

.top {
  top: 0;
}

.button_container:hover .top {
  top: 2px;
}

.button_container.active:hover .top {
  top: 0;
}

.middle {
  top: 10px;
}

.bottom {
  top: 20px;
}

.button_container:hover .bottom {
  top: 18px;
}

.button_container.active:hover .bottom {
  top: 20px;
}

.button_container.active .top {
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  transform: translateY(10px) translateX(0) rotate(45deg);
  background: var(--foundation-yellow-normal);
}

.button_container.active .middle {
  opacity: 0;
}

.button_container.active .bottom {
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  transform: translateY(-10px) translateX(0) rotate(-45deg);
  background: var(--foundation-yellow-normal);
}

.overlay {
  position: fixed;
  background: black;
  visibility: hidden;
  width: 100%;
  height: 0%;
  left: 0;
  top: 0;
  opacity: 0;
  overflow-y: scroll;
  -webkit-transition: opacity 0.35s, height 0.35s, visibility 0.35s;
  transition: opacity 0.35s, height 0.35s, visibility 0.35s;
  z-index: 100;
}
.overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 59%;
  height: 100%;
  background: var(--foundation-white-light);
}
.overlay::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 41%;
  height: 100%;
  background: var(--foundation-blue-normal);
}
.overlay .right {
  position: absolute;
  right: 300px;
  bottom: 0;
  z-index: 10;
}

.overlay.open {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.overlay.open li {
  -webkit-animation: fadeintop 0.5s ease forwards;
  animation: fadeInTop 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.overlay.open li:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.overlay.open li:nth-of-type(7) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.overlay .nav {
  position: relative;
  height: 70%;
  top: 50%;
  text-align: center;
  z-index: 10;
}

.overlay ul {
  list-style: none;
  padding: 0;
  position: relative;
  height: 100%;
  margin-top: 150px;
}

.overlay ul li {
  display: block;
  height: 25%;
  height: calc(100% / 4);
  min-height: 50px;
  position: relative;
  opacity: 0;
  margin-bottom: 20px;
}
.overlay ul li:last-child {
  margin-bottom: 0;
}

.overlay ul li a {
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  transition: 0.35s;
}
.overlay ul li a:hover {
  color: var(--foundation-yellow-normal);
  transition: 0.35s;
}

.overlay ul li a:hover::after,
.overlay ul li a:focus::after,
.overlay ul li a:active::after {
  width: 100%;
}

/*
.overlay ul li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  background: #FFF;
  -webkit-transition: .35s;
  transition: .35s;
}
*/
@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
section#slider {
  height: 100vh;
  position: relative;
}
section#slider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 17px;
  background: var(--foundation-yellow-normal);
  left: 0;
  right: 0;
  bottom: -17px;
}
section#slider .slider-title {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 411px;
}
section#slider .slider-desc {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 486px;
}
section#slider .slider-btn {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-blue-normal);
  width: 274px;
  height: 55px;
  gap: 16px;
}
section#slider .slider-btn span {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
section#slider .wrapper {
  height: 100vh;
  position: relative;
}
section#slider .wrapper .online-appointment {
  position: absolute;
  z-index: 50;
  bottom: 95px;
  right: 575px;
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}
section#slider .wrapper .online-appointment svg {
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  transform-origin: center;
}
section#slider .wrapper .online-appointment:hover {
  transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
}
section#slider .wrapper .online-appointment:hover svg {
  transform: scale(1.5);
  transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
}
section#slider .wrapper .slider-pattern {
  position: absolute;
  right: 0;
  bottom: 0;
}
section#slider::after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--foundation-blue-normal);
  width: 100%;
  max-width: 794px;
}

section#about {
  margin-top: 64px;
  position: relative;
}
section#about .about-pattern {
  position: absolute;
  left: 0;
  top: 50%;
  bottom: 0;
  margin: auto;
}
section#about h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#about h4 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 24px;
}
section#about .content {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 45px;
  gap: 20px;
}
section#about .content p {
  width: 100%;
}
section#about .about-btn {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-blue-normal);
  width: 274px;
  height: 55px;
  gap: 16px;
  margin-top: 45px;
}
section#about .about-btn span {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

section#treatments {
  margin-top: 50px;
  position: relative;
}
section#treatments::before {
  content: "";
  width: 100%;
  height: 400px;
  position: absolute;
  background: var(--foundation-blue-normal);
  bottom: -80px;
  left: 0;
  right: 0;
  z-index: -1;
}
section#treatments .treatment-pattern {
  position: absolute;
  bottom: -80px;
  left: -70px;
}
section#treatments h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#treatments h4 {
  color: var(--foundation-blue-normal);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 500px;
  margin-top: 32px;
  position: relative;
}
section#treatments h4::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  margin: auto;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
}
section#treatments .wrapper {
  max-width: 984px;
  margin-top: 73px;
}
section#treatments .wrapper .treatment-column {
  position: relative;
}
section#treatments .wrapper .treatment-column .caption {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
section#treatments .wrapper .treatment-column .caption p {
  color: var(--foundation-yellow-light);
  font-family: var(--ivymode);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#treatments .wrapper .treatment-column .caption img {
  display: inline-block;
  margin-top: 46px;
}

section#marquee {
  margin-top: 150px;
  position: relative;
}
section#marquee .marquee {
  overflow: hidden;
  border-top: 1px solid var(--foundation-yellow-normal);
  border-bottom: 1px solid var(--foundation-yellow-normal);
  display: flex;
  padding: 20px 0;
}
section#marquee .marquee ul {
  gap: 30px;
}
section#marquee .marquee ul li a {
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

section#blog {
  background: url("../img/blog-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid var(--foundation-yellow-normal);
  margin-top: 60px;
  padding-top: 40px;
  padding-bottom: 50px;
}
section#blog h3 {
  color: var(--foundation-grey-light);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#blog h4 {
  color: var(--foundation-white-light);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 500px;
  margin-top: 25px;
}
section#blog .wrapper {
  margin-top: 40px;
  position: relative;
}
section#blog .wrapper::before {
  content: "";
  position: absolute;
  background: var(--foundation-yellow-normal-hover);
  width: 43px;
  height: 178px;
  left: -24px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
}
section#blog .wrapper::after {
  content: "";
  position: absolute;
  background: var(--foundation-yellow-normal-hover);
  width: 43px;
  height: 178px;
  right: -24px;
  bottom: 80px;
  z-index: 0;
}
section#blog .wrapper .blog-column {
  position: relative;
  z-index: 3;
}
section#blog .wrapper .blog-column .content {
  border: 1px solid rgba(255, 255, 255, 0.47);
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(5.5999999046px);
  padding: 30px 25px;
}
section#blog .wrapper .blog-column .content .category {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#blog .wrapper .blog-column .content .title {
  color: var(--foundation-yellow-light);
  font-family: var(--ivymode);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 10px;
  height: 58px;
}
section#blog .wrapper .blog-column .content .desc {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 20px;
}
section#blog .wrapper .blog-column .content .more-btn {
  gap: 10px;
  margin-top: 25px;
}
section#blog .wrapper .blog-column .content .more-btn span {
  color: var(--foundation-yellow-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#blog .wrapper .all-posts {
  margin-top: 50px;
}
section#blog .wrapper .all-posts a {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-yellow-light);
  gap: 15px;
  height: 55px;
  width: 270px;
}
section#blog .wrapper .all-posts a span {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

footer {
  background: var(--foundation-yellow-light);
  box-shadow: -2px -2px 8px 0 rgba(30, 50, 64, 0.13);
  margin-top: 170px;
}
footer .social-media {
  background: var(--foundation-blue-normal, #152835);
  box-shadow: 0 4px 4px 0 rgba(21, 40, 53, 0.15);
  margin-top: -75px;
  position: relative;
  display: inline-block;
}
footer .social-media a {
  padding: 35px 0;
  display: flex;
  justify-content: center;
}
footer .social-media .sm-col {
  position: relative;
}
footer .social-media .sm-col:last-child::before {
  display: none;
}
footer .social-media .sm-col::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 84px;
  background: var(--foundation-yellow-normal);
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
footer .social-media .social-media-column {
  position: relative;
}
footer .social-media .social-media-column ul li:nth-child(1) {
  color: var(--foundation-yellow-normal);
  font-family: var(--montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
footer .social-media .social-media-column ul li:nth-child(2) {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
footer .footer-logo {
  margin-top: 28px;
  margin-bottom: 44px;
}
footer .footer-menu ul {
  gap: 73px;
}
footer .footer-menu ul li a {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
footer .copyright {
  padding: 50px 0;
}
footer .copyright a {
  gap: 6px;
}
footer .copyright a span {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

section#contact {
  padding-top: 190px;
}
section#contact .left .title {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#contact .left .desc {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 362px;
  margin-top: 40px;
}
section#contact .left ul {
  max-width: 387px;
  margin-top: 64px;
}
section#contact .left ul li {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  gap: 10px;
  margin-bottom: 17px;
}
section#contact .left ul li:last-child {
  margin-bottom: 0;
}
section#contact .left ul li svg {
  width: 16px;
}
section#contact .left ul .show-on-map {
  padding-left: 30px;
}
section#contact .right {
  position: relative;
  padding-right: 32px;
}
section#contact .right .pattern {
  position: absolute;
  right: 0;
  top: -176px;
  z-index: 11;
}
section#contact .right form {
  width: 100%;
  max-width: 824px;
  max-height: 546px;
  height: 100%;
  position: relative;
  z-index: 10;
}
section#contact .right form::before {
  content: "";
  background: var(--foundation-yellow-normal);
  width: 72px;
  height: 311px;
  position: absolute;
  right: -32px;
  top: -70px;
  z-index: -1;
}
section#contact .right form .form-content {
  background: var(--foundation-blue-normal);
  position: relative;
  z-index: 5;
  height: 100%;
}
section#contact .right form .contact-form {
  width: 100%;
  padding: 80px;
}
section#contact .right form .contact-form .form-col-2 {
  display: flex;
  gap: 85px;
}
section#contact .right form .contact-form .form-col-2 .col {
  flex: 1;
}
section#contact .right form .contact-form .form-field {
  position: relative;
  margin-bottom: 40px;
}
section#contact .right form .contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 1px 0;
  border-color: var(--foundation-yellow-normal);
  background: transparent;
  box-shadow: none;
  padding: 0;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
}
section#contact .right form .contact-form .input-text:focus {
  outline: none;
}
section#contact .right form .contact-form .input-text:focus + .label {
  transform: translateY(-24px);
}
section#contact .right form .contact-form .input-text.not-empty + .label {
  transform: translateY(-24px);
}
section#contact .right form .contact-form .label {
  position: absolute;
  left: 0;
  bottom: 11px;
  cursor: text;
  transition: transform 0.2s ease-in-out;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
section#contact .right form .contact-form .checkbox label {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}
section#contact .right form .contact-form button {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-yellow-light);
  gap: 15px;
  height: 55px;
  width: 270px;
}
section#contact .right form .contact-form button span {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
section#contact .right form input[type=checkbox] {
  background: transparent;
  border-color: var(--foundation-yellow-normal);
}
section#contact .right form input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--foundation-yellow-normal);
  border-color: var(--foundation-yellow-normal);
}

.checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em;
}

.checkbox label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}

label {
  cursor: pointer;
}

input[type=checkbox] {
  width: 1.25em;
  height: 1.25em;
  margin: 0;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: var(--color-dark-yellow);
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.08em solid #4F4F4F;
  box-shadow: none;
  outline: none;
}

input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
}

input[type=checkbox]:focus {
  box-shadow: none !important;
  outline: none !important;
}

section#aboutPage {
  padding-top: 360px;
}
section#aboutPage::before {
  content: "";
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 679px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
section#aboutPage .left h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#aboutPage .left h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 24px;
}
section#aboutPage .left .content {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 35px;
}
section#aboutPage .left .content strong {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
section#aboutPage .left .content * {
  margin-bottom: 20px;
}
section#aboutPage .right {
  position: relative;
}
section#aboutPage .right .pattern {
  position: absolute;
  right: 0;
  top: -86px;
}

/* Animations */
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
section#certificates h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#certificates .columns {
  border-top: 1px solid var(--foundation-yellow-normal);
  border-bottom: 1px solid var(--foundation-yellow-normal);
  margin-top: 48px;
  padding: 20px 0;
}

section#documents {
  background: linear-gradient(180deg, #11202A 0%, #273B49 48.44%, #11202A 100%);
  padding: 50px 0;
  margin-top: 111px;
}
section#documents h3 {
  color: var(--foundation-yellow-light);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  margin-bottom: 40px;
}
section#documents .wrapper {
  max-width: 890px;
}
section#documents .wrapper .column {
  text-align: center;
}
section#documents .wrapper .column img {
  display: inline-block;
}
section#documents .wrapper .column p {
  margin-top: 16px;
  color: var(--foundation-yellow-light);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

section#treatmentsPage {
  padding-top: 330px;
  position: relative;
  margin-bottom: 152px;
}
section#treatmentsPage .pattern {
  position: absolute;
  top: 250px;
  right: 300px;
}
section#treatmentsPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 562px;
  z-index: -1;
  right: 0;
  top: 0;
}
section#treatmentsPage h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#treatmentsPage h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 448px;
  margin-top: 32px;
  position: relative;
}
section#treatmentsPage h2::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
  bottom: -25px;
  left: 0;
}

section#treatmentDetailPage {
  padding-top: 360px;
}
section#treatmentDetailPage::before {
  content: "";
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 679px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
section#treatmentDetailPage .left {
  margin-top: 121px;
}
section#treatmentDetailPage .left h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#treatmentDetailPage .left .content {
  margin-top: 43px;
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
section#treatmentDetailPage .left .content p {
  margin-bottom: 20px;
}
section#treatmentDetailPage .right {
  position: relative;
}
section#treatmentDetailPage .right .pattern {
  position: absolute;
  right: 0;
  top: -86px;
}

section#treatmentDetailPageContent h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.32px;
}
section#treatmentDetailPageContent .content {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 30px;
}
section#treatmentDetailPageContent .content p {
  margin-bottom: 20px;
}

section#blogPage {
  padding-top: 330px;
  position: relative;
  margin-bottom: 220px;
}
section#blogPage .pattern {
  position: absolute;
  top: 250px;
  right: 300px;
}
section#blogPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 562px;
  z-index: -1;
  right: 0;
  top: 0;
}
section#blogPage h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#blogPage h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 448px;
  margin-top: 32px;
  position: relative;
}
section#blogPage h2::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
  bottom: -25px;
  left: 0;
}

section#blogDetailPage {
  position: relative;
  padding-top: 275px;
}
section#blogDetailPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  right: 0;
  top: 0;
  width: 794px;
  height: 465px;
  z-index: -1;
}
section#blogDetailPage .cover {
  position: relative;
}
section#blogDetailPage .cover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 15px;
  background: var(--foundation-yellow-normal);
  inset: 0;
  margin: auto;
  z-index: -1;
}
section#blogDetailPage h1 {
  color: var(--foundation-blue-normal);
  text-align: center;
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 628px;
  position: relative;
  margin-top: 20px;
}
section#blogDetailPage h1::before {
  content: "";
  position: absolute;
  width: 185px;
  height: 1px;
  background: #152835;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: auto;
}
section#blogDetailPage h2 {
  color: var(--foundation-dark);
  font-family: var(--montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 80px;
}
section#blogDetailPage .wrapper {
  margin-top: 20px;
  color: #000;
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 75px;
}
section#blogDetailPage .wrapper p {
  margin-bottom: 20px;
}

section#galleryPage {
  padding-top: 330px;
  position: relative;
  margin-bottom: 220px;
}
section#galleryPage .pattern {
  position: absolute;
  top: 250px;
  right: 300px;
}
section#galleryPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 562px;
  z-index: -1;
  right: 0;
  top: 0;
}
section#galleryPage h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#galleryPage h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 448px;
  margin-top: 32px;
  position: relative;
}
section#galleryPage h2::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
  bottom: -25px;
  left: 0;
}

section#commentsPage {
  padding-top: 330px;
  position: relative;
  margin-bottom: 220px;
}
section#commentsPage .pattern {
  position: absolute;
  top: 250px;
  right: 300px;
}
section#commentsPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 562px;
  z-index: -1;
  right: 0;
  top: 0;
}
section#commentsPage h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#commentsPage h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 448px;
  margin-top: 32px;
  position: relative;
}
section#commentsPage h2::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
  bottom: -25px;
  left: 0;
}

section#commentsPageContent .comment-column {
  border-bottom: 10px solid var(--foundation-yellow-normal);
  background: var(--foundation-blue-normal);
  padding: 70px 30px;
  position: relative;
  padding-top: 120px;
  margin-bottom: 50px;
}
section#commentsPageContent .comment-column::before {
  content: "";
  width: 100%;
  height: 60px;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
section#commentsPageContent .comment-column .desc {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 20px;
  margin-bottom: 32px;
}
section#commentsPageContent .comment-column .comment-footer p {
  color: var(--foundation-yellow-normal);
  font-family: var(--ivymode);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#commentsPageContent .comment-column .comment-header-block {
  width: 116px;
  height: 116px;
  border: 1px solid var(--foundation-blue-normal);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  border-radius: 100%;
}
section#commentsPageContent .comment-column .comment-header-block .comment-header {
  background: var(--foundation-yellow-normal);
  filter: drop-shadow(0 4px 4px var(--foundation-dark-blue));
  width: 93px;
  height: 93px;
  border-radius: 100%;
}
section#commentsPageContent .comment-column .comment-header-block .comment-header p {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

section#shareComments .wrapper {
  max-width: 935px;
}
section#shareComments .wrapper h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#shareComments .wrapper h4 {
  color: var(--foundation-blue-normal);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.18px;
  margin-top: 32px;
  max-width: 435px;
}
section#shareComments .wrapper .comment-box {
  border-bottom: 10px solid var(--foundation-yellow-normal);
  background: var(--foundation-blue-normal);
  box-shadow: 0 4px 6px 0 rgba(21, 40, 53, 0.15);
  margin-top: 50px;
}
section#shareComments .wrapper .comment-box form {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}
section#shareComments .wrapper .comment-box form #full-stars .rating-group {
  display: inline-flex;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__icon {
  pointer-events: none;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__input--none {
  display: none;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 2rem;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__icon--star {
  color: #96774C;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}
section#shareComments .wrapper .comment-box form #full-stars .rating-group:hover .rating__label .rating__icon--star {
  color: #96774C;
}
section#shareComments .wrapper .comment-box form #full-stars .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}
section#shareComments .wrapper .comment-box form .form-content {
  background: var(--foundation-blue-normal);
  position: relative;
  z-index: 5;
  height: 100%;
}
section#shareComments .wrapper .comment-box form .contact-form {
  width: 100%;
  padding: 50px 150px;
}
section#shareComments .wrapper .comment-box form .contact-form .form-col-2 {
  display: flex;
  gap: 85px;
}
section#shareComments .wrapper .comment-box form .contact-form .form-col-2 .col {
  flex: 1;
}
section#shareComments .wrapper .comment-box form .contact-form .form-field {
  position: relative;
  margin-bottom: 40px;
}
section#shareComments .wrapper .comment-box form .contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 1px 0;
  border-color: var(--foundation-yellow-normal);
  background: transparent;
  box-shadow: none;
  padding: 0;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
}
section#shareComments .wrapper .comment-box form .contact-form .input-text:focus {
  outline: none;
}
section#shareComments .wrapper .comment-box form .contact-form .input-text:focus + .label {
  transform: translateY(-24px);
}
section#shareComments .wrapper .comment-box form .contact-form .input-text.not-empty + .label {
  transform: translateY(-24px);
}
section#shareComments .wrapper .comment-box form .contact-form .label {
  position: absolute;
  left: 0;
  bottom: 11px;
  cursor: text;
  transition: transform 0.2s ease-in-out;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
section#shareComments .wrapper .comment-box form .contact-form .checkbox label {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}
section#shareComments .wrapper .comment-box form .contact-form button {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-yellow-light);
  gap: 15px;
  height: 55px;
  width: 270px;
}
section#shareComments .wrapper .comment-box form .contact-form button span {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
section#shareComments .wrapper .comment-box form input[type=checkbox] {
  background: transparent;
  border-color: var(--foundation-yellow-normal);
}
section#shareComments .wrapper .comment-box form input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--foundation-yellow-normal);
  border-color: var(--foundation-yellow-normal);
}

section#contactPage {
  padding-top: 330px;
  position: relative;
}
section#contactPage .pattern {
  position: absolute;
  top: 250px;
  right: 300px;
}
section#contactPage::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 794px;
  height: 562px;
  z-index: -1;
  right: 0;
  top: 0;
}
section#contactPage h1 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
section#contactPage h2 {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 448px;
  margin-top: 32px;
  position: relative;
}
section#contactPage h2::before {
  content: "";
  position: absolute;
  background: var(--foundation-blue-normal);
  width: 185px;
  height: 1px;
  bottom: -25px;
  left: 0;
}

section#contactPageContent .left ul {
  margin-top: 64px;
}
section#contactPageContent .left ul li {
  color: var(--foundation-blue-normal);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  gap: 10px;
  margin-bottom: 17px;
}
section#contactPageContent .left ul li:last-child {
  margin-bottom: 0;
}
section#contactPageContent .left ul li svg {
  width: 16px;
}
section#contactPageContent .left ul .show-on-map {
  padding-left: 30px;
}
section#contactPageContent .right {
  position: relative;
  z-index: 10;
}

section#contactForm {
  padding-top: 68px;
  border-top: 14px solid var(--foundation-yellow-normal);
  margin-top: -20px;
}
section#contactForm .wrapper {
  max-width: 824px;
}
section#contactForm .wrapper h3 {
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
section#contactForm .wrapper h4 {
  color: var(--foundation-blue-normal);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 500px;
  margin-top: 32px;
}
section#contactForm .wrapper .contact-form-box {
  background: var(--foundation-blue-normal);
  box-shadow: 0 4px 6px 0 rgba(21, 40, 53, 0.15);
  margin-top: 50px;
  position: relative;
}
section#contactForm .wrapper .contact-form-box .contact-form-pattern {
  position: absolute;
  top: -68px;
  left: -166px;
  z-index: 5;
}
section#contactForm .wrapper .contact-form-box::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 311px;
  background: var(--foundation-yellow-normal);
  bottom: -46px;
  right: -36px;
}
section#contactForm .wrapper .contact-form-box form {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}
section#contactForm .wrapper .contact-form-box form .form-content {
  background: var(--foundation-blue-normal);
  position: relative;
  z-index: 5;
  height: 100%;
}
section#contactForm .wrapper .contact-form-box form .contact-form {
  width: 100%;
  padding: 50px 95px;
}
section#contactForm .wrapper .contact-form-box form .contact-form .form-col-2 {
  display: flex;
  gap: 85px;
}
section#contactForm .wrapper .contact-form-box form .contact-form .form-col-2 .col {
  flex: 1;
}
section#contactForm .wrapper .contact-form-box form .contact-form .form-field {
  position: relative;
  margin-bottom: 40px;
}
section#contactForm .wrapper .contact-form-box form .contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 1px 0;
  border-color: var(--foundation-yellow-normal);
  background: transparent;
  box-shadow: none;
  padding: 0;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
}
section#contactForm .wrapper .contact-form-box form .contact-form .input-text:focus {
  outline: none;
}
section#contactForm .wrapper .contact-form-box form .contact-form .input-text:focus + .label {
  transform: translateY(-24px);
}
section#contactForm .wrapper .contact-form-box form .contact-form .input-text.not-empty + .label {
  transform: translateY(-24px);
}
section#contactForm .wrapper .contact-form-box form .contact-form .label {
  position: absolute;
  left: 0;
  bottom: 11px;
  cursor: text;
  transition: transform 0.2s ease-in-out;
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
section#contactForm .wrapper .contact-form-box form .contact-form .checkbox label {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}
section#contactForm .wrapper .contact-form-box form .contact-form button {
  border-radius: 27.5px;
  border: 1px solid var(--foundation-yellow-light);
  gap: 15px;
  height: 55px;
  width: 270px;
}
section#contactForm .wrapper .contact-form-box form .contact-form button span {
  color: var(--foundation-yellow-light);
  font-family: var(--montserrat);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
section#contactForm .wrapper .contact-form-box form input[type=checkbox] {
  background: transparent;
  border-color: var(--foundation-yellow-normal);
}
section#contactForm .wrapper .contact-form-box form input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--foundation-yellow-normal);
  border-color: var(--foundation-yellow-normal);
}

@media only screen and (max-width: 2560px) {
  section#slider::after {
    max-width: 1150px;
  }
  section#slider .wrapper .online-appointment {
    bottom: 350px;
  }
  section#slider .wrapper .slider-pattern {
    bottom: 200px;
  }
}
@media only screen and (max-width: 1920px) {
  section#slider::after {
    max-width: 794px;
  }
  section#slider .wrapper .online-appointment {
    bottom: 95px;
  }
  section#slider .wrapper .slider-pattern {
    bottom: 0;
  }
}
@media only screen and (max-width: 1600px) {
  section#slider::after {
    max-width: 700px;
  }

  section#aboutPage::before {
    width: 40%;
    height: 470px;
  }

  section#treatmentDetailPage::before {
    width: 40%;
    height: 470px;
  }
}
@media only screen and (max-width: 1440px) {
  section#slider::after {
    max-width: 550px;
  }
  section#slider .wrapper .online-appointment {
    bottom: 95px;
  }
  section#slider .wrapper .slider-pattern {
    bottom: 0;
  }

  section#commentsPage::before {
    width: 40%;
  }
  section#commentsPage .pattern {
    right: 170px;
  }

  section#blogPage::before {
    width: 40%;
  }
  section#blogPage .pattern {
    right: 170px;
  }

  section#blogDetailPage::before {
    width: 40%;
  }

  section#contactPage::before {
    width: 40%;
  }
  section#contactPage .pattern {
    right: 170px;
  }
}
@media only screen and (max-width: 1280px) {
  .container {
    max-width: 90%;
  }

  section#slider .slider-title, section#slider .slider-desc {
    left: 5% !important;
  }
  section#slider .slider-title {
    font-size: 35px;
  }
  section#slider .slider-desc {
    top: 430px !important;
  }
  section#slider .slider-btn {
    margin-left: 60px;
  }

  header {
    padding-top: 40px;
  }
  header .logo {
    max-width: 180px;
  }

  section#about h3 {
    font-size: 35px;
  }

  section#treatments h3 {
    font-size: 35px;
  }
  section#treatments .wrapper .treatment-column .caption p {
    font-size: 30px;
  }

  section#marquee .marquee ul li a {
    font-size: 30px;
  }

  section#blog h3 {
    font-size: 35px;
  }
  section#blog .wrapper .blog-column .content .title {
    font-size: 20px;
  }

  section#contact .left .title {
    font-size: 35px;
  }

  section#aboutPage {
    padding-top: 240px;
  }
  section#aboutPage .left h1 {
    font-size: 35px;
  }

  section#certificates h3 {
    font-size: 35px;
  }

  section#documents {
    margin-top: 50px;
  }
  section#documents h3 {
    font-size: 35px;
  }
  section#documents .wrapper .column p {
    font-size: 20px;
  }

  section#treatmentsPage {
    padding-top: 300px;
  }
  section#treatmentsPage::before {
    width: 55%;
  }
  section#treatmentsPage h1 {
    font-size: 35px;
  }

  section#treatmentDetailPage {
    padding-top: 250px;
  }
  section#treatmentDetailPage::before {
    width: 40%;
  }
  section#treatmentDetailPage .left h1 {
    font-size: 35px;
  }
  section#treatmentDetailPage .right .pattern img {
    max-width: 150px;
  }

  section#treatmentDetailPageContent h3 {
    font-size: 25px;
  }

  section#commentsPage h1 {
    font-size: 35px;
  }
  section#commentsPage h2 {
    margin-top: 15px;
  }
  section#commentsPage .pattern {
    right: 150px;
  }

  section#shareComments .wrapper h3 {
    font-size: 35px;
  }
  section#shareComments .wrapper h4 {
    margin-top: 15px;
  }

  section#blogPage h1 {
    font-size: 35px;
  }
  section#blogPage h2 {
    font-size: 16px;
    margin-top: 10px;
  }

  section#blogDetailPage h1 {
    font-size: 35px;
  }
  section#blogDetailPage h2 {
    font-size: 20px;
  }

  section#contactPage {
    padding-top: 250px;
  }
  section#contactPage h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 1024px) {
  section#slider::after {
    max-width: 420px;
  }
  section#slider .wrapper .online-appointment {
    right: 375px;
  }
  section#slider .slider-desc {
    top: 330px !important;
    max-width: 400px;
  }
  section#slider .slider-btn span {
    font-size: 16px;
  }

  section#about h3 {
    font-size: 30px;
  }
  section#about h4 {
    font-size: 18px;
    margin-top: 10px;
  }
  section#about .about-btn span {
    font-size: 16px;
  }

  section#treatments h3 {
    font-size: 30px;
  }
  section#treatments .wrapper .treatment-column .caption p {
    font-size: 25px;
  }
  section#treatments .treatment-pattern img {
    max-width: 100px;
  }

  section#marquee .marquee ul li a {
    font-size: 25px;
  }

  section#blog h3 {
    font-size: 30px;
  }
  section#blog .wrapper .all-posts a span {
    font-size: 16px;
  }

  footer {
    margin-top: 100px;
  }
  footer .social-media .social-media-column ul li:nth-child(1) {
    font-size: 18px;
  }
  footer .social-media .social-media-column ul li:nth-child(2) {
    margin-top: 5px;
  }
  footer .footer-logo img {
    max-width: 150px;
  }
  footer .footer-menu ul li a {
    font-size: 16px;
  }

  section#contact {
    padding-top: 50px;
  }
  section#contact .left ul {
    margin-top: 20px;
  }
  section#contact .left ul li {
    font-size: 16px;
  }
  section#contact .left .title {
    font-size: 30px;
  }
  section#contact .left .desc {
    margin-top: 20px;
    font-size: 16px;
  }
  section#contact .right form::before {
    top: -20px;
  }
  section#contact .right form .contact-form {
    padding: 50px;
  }
  section#contact .right form .contact-form button span {
    font-size: 16px;
  }
  section#contact .right .pattern {
    top: -50px;
  }
  section#contact .right .pattern img {
    max-width: 100px;
  }

  section#aboutPage {
    padding-top: 200px;
  }
  section#aboutPage .left h1 {
    font-size: 30px;
  }
  section#aboutPage .left h2 {
    font-size: 18px;
    margin-top: 10px;
  }
  section#aboutPage .left .content {
    font-size: 16px;
  }
  section#aboutPage .left .content strong {
    font-size: 16px;
  }
  section#aboutPage .right .pattern {
    top: -40px;
  }
  section#aboutPage .right .pattern img {
    max-width: 100px;
  }

  section#certificates h3 {
    font-size: 30px;
  }
  section#certificates .columns {
    margin-top: 30px;
  }

  section#documents h3 {
    font-size: 30px;
  }
  section#documents .wrapper .column p {
    font-size: 18px;
  }

  .overlay ul li {
    min-height: 30px;
  }
  .overlay ul li a {
    font-size: 30px;
  }
  .overlay .right {
    bottom: 100px;
  }
  .overlay .right img {
    max-width: 150px;
  }

  section#treatmentsPage {
    margin-bottom: 200px;
  }
  section#treatmentsPage::before {
    width: 50%;
  }
  section#treatmentsPage .pattern {
    right: 140px;
  }
  section#treatmentsPage h1 {
    font-size: 30px;
  }
  section#treatmentsPage h2 {
    font-size: 16px;
    margin-top: 20px;
  }

  section#treatmentDetailPage {
    padding-top: 200px;
  }
  section#treatmentDetailPage .left {
    margin-top: 50px;
  }
  section#treatmentDetailPage .left h1 {
    font-size: 30px;
  }
  section#treatmentDetailPage .left .content {
    font-size: 16px;
    margin-top: 20px;
  }

  section#treatmentDetailPageContent h3 {
    font-size: 20px;
  }

  section#commentsPage h1 {
    font-size: 30px;
  }
  section#commentsPage h2 {
    font-size: 16px;
  }
  section#commentsPage .pattern {
    right: 100px;
  }

  section#shareComments .wrapper h3 {
    font-size: 30px;
  }
  section#shareComments .wrapper h4 {
    font-size: 16px;
  }

  section#blogDetailPage h1 {
    font-size: 30px;
  }
  section#blogDetailPage h2 {
    font-size: 20px;
    margin-top: 50px;
  }

  section#contactPage h1 {
    font-size: 30px;
  }
  section#contactPage .pattern {
    right: 100px;
  }
  section#contactPage h2 {
    font-size: 16px;
    margin-top: 10px;
  }

  section#contactForm {
    margin-top: 50px;
  }
  section#contactForm .wrapper h3 {
    font-size: 30px;
  }
  section#contactForm .wrapper h4 {
    font-size: 16px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  body, html {
    overflow-x: hidden;
  }

  body.active header.active::before {
    background: var(--foundation-white-light);
  }

  section#slider {
    height: 38.6vh;
  }
  section#slider::after {
    display: none;
  }
  section#slider .slider-cover {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    height: auto !important;
  }
  section#slider .slider-pattern {
    display: none;
  }
  section#slider .wrapper {
    height: 50vh;
  }
  section#slider .wrapper .online-appointment {
    left: 0;
    right: 0;
    margin: auto;
    animation: unset;
    display: none;
  }

  section#about {
    margin-top: 20px;
  }
  section#about .left .cover {
    width: 100%;
  }
  section#about .about-pattern {
    display: none;
  }
  section#about .content {
    margin-top: 10px;
    font-size: 16px;
    gap: 10px;
  }
  section#about .about-btn {
    width: 100%;
    margin-top: 20px;
  }
  section#about .right-content {
    margin-top: 0;
  }
  section#about h3 {
    font-size: 24px;
  }
  section#about h4 {
    font-size: 16px;
  }

  section#treatments {
    margin-top: 20px;
  }
  section#treatments::before {
    bottom: -30px;
  }
  section#treatments h3 {
    text-align: left;
    font-size: 24px;
  }
  section#treatments h4 {
    text-align: left;
    margin-top: 20px;
    font-size: 16px;
  }
  section#treatments h4::before {
    right: auto;
  }
  section#treatments .wrapper {
    max-width: 100%;
    margin-top: 35px;
  }
  section#treatments .wrapper .treatment-column .caption p {
    font-size: 20px;
  }
  section#treatments .treatment-pattern {
    display: none;
  }

  section#marquee {
    margin-top: 50px;
  }
  section#marquee .marquee ul li a {
    font-size: 20px;
  }

  section#blog {
    margin-top: 20px;
  }
  section#blog h3 {
    text-align: left;
    font-size: 24px;
  }
  section#blog h4 {
    text-align: left;
    max-width: 100%;
    font-size: 16px;
  }
  section#blog .wrapper {
    margin-top: 10px;
  }
  section#blog .wrapper .all-posts {
    margin-top: 20px;
  }
  section#blog .wrapper .all-posts a {
    width: 100%;
  }
  section#blog .wrapper .blog-column .content .title {
    font-size: 18px;
  }
  section#blog .wrapper .blog-column .content .desc {
    font-size: 16px;
  }
  section#blog .wrapper .blog-column .content .more-btn span {
    font-size: 16px;
  }

  section#contact {
    padding-top: 0;
  }
  section#contact .left ul {
    max-width: 100%;
    margin-top: 20px;
  }
  section#contact .left .title {
    font-size: 24px;
  }
  section#contact .left .desc {
    font-size: 16px;
    margin-top: 10px;
  }
  section#contact .right {
    padding-right: 0;
  }
  section#contact .right .pattern {
    display: none;
  }
  section#contact .right form::before {
    display: none;
  }
  section#contact .right form .contact-form {
    padding: 30px;
  }
  section#contact .right form .contact-form .form-col-2 {
    display: block;
  }

  footer {
    margin-top: 200px;
  }
  footer .footer-menu ul {
    gap: 0;
  }
  footer .footer-menu ul li {
    width: 100%;
    text-align: center;
  }
  footer .footer-menu ul li a {
    text-align: center;
  }
  footer .social-media {
    margin-top: 20px;
  }
  footer .social-media a {
    padding: 0;
    justify-content: left;
  }

  .overlay::after {
    background: var(--foundation-white-light);
  }
  .overlay ul {
    margin-top: 0;
  }
  .overlay ul li {
    min-height: 22px;
  }
  .overlay ul li a {
    font-size: 22px;
  }

  header {
    padding-top: 20px;
  }
  header .logo {
    max-width: 100px;
  }

  section#aboutPage {
    padding-top: 100px;
  }
  section#aboutPage .flex-wrap {
    flex-wrap: wrap-reverse;
  }
  section#aboutPage::before {
    display: none;
  }
  section#aboutPage .left {
    margin-top: -20px;
  }
  section#aboutPage .left h1 {
    font-size: 24px;
  }
  section#aboutPage .left h2 {
    font-size: 16px;
  }
  section#aboutPage .left .content {
    margin-top: 10px;
  }

  section#certificates h3 {
    font-size: 24px;
  }
  section#certificates .columns .column {
    width: 50%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }

  section#documents {
    padding: 30px 0;
  }
  section#documents h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  section#documents .wrapper .doc-column {
    width: 50%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
  section#documents .wrapper .column p {
    font-size: 16px;
  }

  section#treatmentsPage {
    padding-top: 150px;
    margin-bottom: 0;
  }
  section#treatmentsPage::before {
    display: none;
  }
  section#treatmentsPage .pattern {
    display: none;
  }
  section#treatmentsPage h1 {
    font-size: 24px;
  }
  section#treatmentsPage h2 {
    margin-top: 10px;
  }

  section#treatmentDetailPage {
    padding-top: 100px;
  }
  section#treatmentDetailPage::before {
    display: none;
  }
  section#treatmentDetailPage .left {
    margin-top: -20px;
  }
  section#treatmentDetailPage .left h1 {
    font-size: 24px;
  }
  section#treatmentDetailPage .flex-wrap {
    flex-wrap: wrap-reverse;
  }
  section#treatmentDetailPage .right .pattern {
    top: -40px;
  }
  section#treatmentDetailPage .right .pattern img {
    max-width: 100px;
  }

  section#treatmentDetailPageContent h3 {
    font-size: 18px;
  }
  section#treatmentDetailPageContent .px-5 {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
  section#treatmentDetailPageContent .my-5 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
  section#treatmentDetailPageContent .content {
    margin-top: 10px;
  }
  section#treatmentDetailPageContent .content *:last-child {
    margin-bottom: 0;
  }

  section#commentsPage {
    padding-top: 120px;
    margin-bottom: 100px;
  }
  section#commentsPage::before {
    display: none;
  }
  section#commentsPage .pattern {
    display: none;
  }
  section#commentsPage h1 {
    font-size: 24px;
  }
  section#commentsPage h2 {
    margin-top: 10px;
  }

  section#commentsPageContent .comment-column .desc {
    font-size: 16px;
  }
  section#commentsPageContent .comment-column .comment-footer p {
    font-size: 16px;
  }

  section#shareComments .wrapper h3 {
    font-size: 24px;
  }
  section#shareComments .wrapper h4 {
    margin-top: 10px;
  }
  section#shareComments .wrapper .comment-box form .contact-form {
    padding: 25px;
  }
  section#shareComments .wrapper .comment-box form .contact-form .form-col-2 {
    display: block;
  }
  section#shareComments .wrapper .comment-box form .contact-form button span {
    font-size: 16px;
  }

  section#blogPage {
    padding-top: 120px;
    margin-bottom: 70px;
  }
  section#blogPage::before {
    display: none;
  }
  section#blogPage .pattern {
    display: none;
  }
  section#blogPage h1 {
    font-size: 24px;
  }

  section#blogDetailPage {
    padding-top: 100px;
  }
  section#blogDetailPage::before {
    display: none;
  }
  section#blogDetailPage h1 {
    font-size: 24px;
  }
  section#blogDetailPage h2 {
    font-size: 18px;
  }
  section#blogDetailPage .wrapper {
    font-size: 16px;
  }

  section#contactPage {
    padding-top: 120px;
  }
  section#contactPage::before {
    display: none;
  }
  section#contactPage .pattern {
    display: none;
  }
  section#contactPage h1 {
    font-size: 24px;
  }

  section#contactPageContent .left ul {
    margin-top: 25px;
  }
  section#contactPageContent .left ul li {
    font-size: 16px;
  }

  section#contactForm {
    margin-top: 25px;
    padding-top: 25px;
  }
  section#contactForm .wrapper h3 {
    font-size: 24px;
  }
  section#contactForm .wrapper .contact-form-box {
    margin-top: 25px;
  }
  section#contactForm .wrapper .contact-form-box::after {
    display: none;
  }
  section#contactForm .wrapper .contact-form-box .contact-form-pattern {
    display: none;
  }
  section#contactForm .wrapper .contact-form-box form .contact-form {
    padding: 50px 25px;
  }
  section#contactForm .wrapper .contact-form-box form .contact-form .form-col-2 {
    display: block;
  }

  .masonry-container {
    column-count: 1;
    column-gap: 40px;
  }
}
/* Effects */
.img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 535px;
}
.img-container img {
  width: 100%;
  opacity: 0;
}
.img-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background: var(--foundation-yellow-normal);
}

.large-text-animation {
  overflow: hidden;
}