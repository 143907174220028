// Add your codes here
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --foundation-blue-normal: #152835;
  --ivymode: "ivymode", sans-serif;
  --montserrat: 'Montserrat', sans-serif;
  --foundation-yellow-normal: #EECF8A;
  --foundation-grey-light:#f7f7f7;
  --foundation-white-light:#fff;
  --foundation-yellow-light: #FDFAF3;
  --foundation-yellow-normal-hover: #D6BA7C;
  --foundation-dark: #000;
  --foundation-dark-blue: #0D1820;
}

.textify .word,
.textify .char {
  display: inline-block;
  will-change: transform, opacity;
}

.textify .char {
  position: relative;
}

.textify .char::before,
.textify .char::after,
.textify .word::after,
.textify .word::before,
.textify .line::after,
.textify .line::before {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  display: none;
}

.textify .char::before,
.textify .char::after {
  content: attr(data-char);
}

.textify .word::after,
.textify .word::before {
  content: attr(data-word);
}

.textify .line::after,
.textify .line::before {
  content: attr(data-line);
}

.textify .line-box,
.textify .line {
  overflow: hidden;
}

body{
  overflow-x:hidden;
}

header{
  padding-top:76px;
  position: fixed;
  left:0;
  right:0;
  top:0;
  z-index:101;
  transition: all .5s ease-out;
  .logo{
    display: block;
    transition: all .5s ease-out;
  }
  .icon{
    display: none;
    transition: all .5s ease-out;
  }
  ul{
    gap:44px;
    li{
      a{
        gap:12px;
        color: var(--foundation-yellow-normal);
        font-family: var(--montserrat);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.42px;
      }
    }
  }
}

header.active{
  background: var(--foundation-white-light);
  padding:20px 0;
  transition: all .5s ease-out;
  .logo{
    display: none;
    transition: all .5s ease-out;
  }
  .icon{
    display: block;
    transition: all .5s ease-out;
  }
}

body.active{
  overflow: hidden;
  header.active{
    &::before{
      content:"";
      position: absolute;
      width:41.5%;
      height:100%;
      right:0;
      bottom:0;
      top:0;
      background: var(--foundation-blue-normal);
    }
    .logo{
      display: block;
    }
    .icon{
      display: none;
    }
  }
}

.button_container {
  position: relative;
  width: 30px;
  height: 25px;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
  margin-left:auto;
}

.button_container span {
  width: 30px;
  height: 2px;
  position: absolute;
  background: var(--foundation-yellow-normal);
  transition: all .35s ease;
}

.masonry-container {
  column-count: 3;
  column-gap: 40px;
}

.masonry-column{
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  break-inside: avoid;
}

.top {
  top: 0;
}

.button_container:hover .top {
  top: 2px;
}

.button_container.active:hover .top {
  top: 0;
}

.middle {
  top: 10px;
}

.bottom {
  top: 20px;
}

.button_container:hover .bottom {
  top: 18px;
}

.button_container.active:hover .bottom {
  top: 20px;
}

.button_container.active .top {
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  transform: translateY(10px) translateX(0) rotate(45deg);
  background: var(--foundation-yellow-normal);
}

.button_container.active .middle {
  opacity: 0;
}

.button_container.active .bottom {
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  transform: translateY(-10px) translateX(0) rotate(-45deg);
  background: var(--foundation-yellow-normal);
}

.overlay {
  position: fixed;
  background: black;
  visibility: hidden;
  width: 100%;
  height: 0%;
  left: 0;
  top: 0;
  opacity: 0;
  overflow-y: scroll;
  -webkit-transition: opacity .35s, height .35s, visibility .35s;
  transition: opacity .35s, height .35s, visibility .35s;
  z-index: 100;
  &::before{
    content:"";
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    width:59%;
    height:100%;
    background: var(--foundation-white-light);
  }
  &::after{
    content:"";
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width:41%;
    height:100%;
    background: var(--foundation-blue-normal);
  }
  .right{
    position: absolute;
    right:300px;
    bottom:0;
    z-index:10;
  }
}

.overlay.open {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.overlay.open li {
  -webkit-animation: fadeintop .5s ease forwards;
  animation: fadeInTop .5s ease forwards;
  -webkit-animation-delay: .35s;
  animation-delay: .35s;
}

.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: .45s;
  animation-delay: .45s;
}

.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: .50s;
  animation-delay: .50s;
}

.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: .55s;
  animation-delay: .55s;
}

.overlay.open li:nth-of-type(6) {
  -webkit-animation-delay: .60s;
  animation-delay: .60s;
}

.overlay.open li:nth-of-type(7) {
  -webkit-animation-delay: .65s;
  animation-delay: .65s;
}

.overlay .nav {
  position: relative;
  height: 70%;
  top: 50%;
  text-align: center;
  z-index:10;
}

.overlay ul {
  list-style: none;
  padding: 0;
  position: relative;
  height: 100%;
  margin-top:150px;
}

.overlay ul li {
  display: block;
  height: 25%;
  height: calc(100% / 4);
  min-height: 50px;
  position: relative;
  opacity: 0;
  margin-bottom:20px;
  &:last-child{
    margin-bottom:0;
  }
}

.overlay ul li a {
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  color: var(--foundation-blue-normal);
  font-family: var(--ivymode);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  transition: .35s;
  &:hover{
    color: var(--foundation-yellow-normal);
    transition: .35s;
  }
}

.overlay ul li a:hover::after,
.overlay ul li a:focus::after,
.overlay ul li a:active::after {
  width: 100%;
}

/*
.overlay ul li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  background: #FFF;
  -webkit-transition: .35s;
  transition: .35s;
}
*/

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

section#slider{
  height:100vh;
  position: relative;
  &::before{
    content:"";
    position: absolute;
    width:100%;
    height:17px;
    background: var(--foundation-yellow-normal);
    left:0;
    right:0;
    bottom:-17px;
  }
  .slider-title{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:411px;
  }
  .slider-desc{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:486px;
  }
  .slider-btn{
    border-radius: 27.5px;
    border: 1px solid var(--foundation-blue-normal);
    width:274px;
    height:55px;
    gap:16px;
    span{
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
    }
  }
  .wrapper{
    height:100vh;
    position: relative;
    .online-appointment{
      position: absolute;
      z-index:50;
      bottom:95px;
      right:575px;
      -webkit-animation:spin 10s linear infinite;
      -moz-animation:spin 10s linear infinite;
      animation:spin 10s linear infinite;
      svg{
        transform: scale(1);
        transition: transform .5s cubic-bezier(0.65,0,0.35,1);
        transform-origin: center;
      }
      &:hover{
        transition: transform .5s cubic-bezier(0.65,0,0.35,1);
        svg{
          transform: scale(1.5);
          transition: transform .5s cubic-bezier(0.65,0,0.35,1);
        }
      }
    }
    .slider-pattern{
      position: absolute;
      right:0;
      bottom:0;
    }
  }
  &::after{
    content:"";
    position: absolute;
    z-index:-1;
    right:0;
    top:0;
    bottom:0;
    background: var(--foundation-blue-normal);
    width:100%;
    max-width:794px;
  }
}

section#about{
  margin-top:64px;
  position: relative;
  .about-pattern{
    position: absolute;
    left:0;
    top:50%;
    bottom:0;
    margin:auto;
  }
  h3{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h4{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top:24px;
  }
  .content{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-top:45px;
    gap:20px;
    p{
      width:100%;
    }
  }
  .about-btn{
    border-radius: 27.5px;
    border: 1px solid var(--foundation-blue-normal);
    width:274px;
    height:55px;
    gap:16px;
    margin-top:45px;
    span{
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
    }
  }
}

section#treatments{
  margin-top:50px;
  position: relative;
  &::before{
    content:"";
    width:100%;
    height:400px;
    position: absolute;
    background: var(--foundation-blue-normal);
    bottom:-80px;
    left:0;
    right:0;
    z-index:-1;
  }
  .treatment-pattern{
    position: absolute;
    bottom:-80px;
    left:-70px;
  }
  h3{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
  }
  h4{
    color: var(--foundation-blue-normal);
    text-align: center;
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:500px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position: absolute;
      left:0;
      right:0;
      bottom:-25px;
      margin:auto;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
    }
  }
  .wrapper{
    max-width:984px;
    margin-top:73px;
    .treatment-column{
      position: relative;
      .caption{
        position:absolute;
        bottom:-30px;
        left:0;
        right:0;
        margin:auto;
        text-align: center;
        p{
          color: var(--foundation-yellow-light);
          font-family: var(--ivymode);
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-align:center;
        }
        img{
          display: inline-block;
          margin-top:46px;
        }
      }
    }
  }
}

section#marquee{
  margin-top:150px;
  position: relative;
  .marquee {
    overflow: hidden;
    border-top: 1px solid var(--foundation-yellow-normal);
    border-bottom: 1px solid var(--foundation-yellow-normal);
    display: flex;
    padding:20px 0;
  }

  .marquee ul{
    gap:30px;
  }

  .marquee ul li a{
    white-space: nowrap;
    text-transform: uppercase;
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

section#blog{
  background:url('../img/blog-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  border-top:10px solid var(--foundation-yellow-normal);
  margin-top:60px;
  padding-top:40px;
  padding-bottom:50px;
  h3{
    color: var(--foundation-grey-light);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
  }
  h4{
    color: var(--foundation-white-light);
    text-align: center;
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:500px;
    margin-top:25px;
  }
  .wrapper{
    margin-top:40px;
    position: relative;
    &::before{
      content:"";
      position: absolute;
      background: var(--foundation-yellow-normal-hover);
      width: 43px;
      height: 178px;
      left:-24px;
      top:0;
      bottom:0;
      margin:auto;
      z-index:0;
    }
    &::after{
      content:"";
      position: absolute;
      background: var(--foundation-yellow-normal-hover);
      width: 43px;
      height: 178px;
      right:-24px;
      bottom:80px;
      z-index:0;
    }
    .blog-column{
      position: relative;
      z-index:3;
      .content{
        border: 1px solid rgba(255, 255, 255, 0.47);
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(5.599999904632568px);
        padding:30px 25px;
        .category{
          color: var(--foundation-yellow-light);
          font-family: var(--montserrat);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
        .title{
          color: var(--foundation-yellow-light);
          font-family: var(--ivymode);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top:10px;
          height:58px;
        }
        .desc{
          color: var(--foundation-yellow-light);
          font-family: var(--montserrat);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top:20px;
        }
        .more-btn{
          gap:10px;
          margin-top:25px;
          span{
            color: var(--foundation-yellow-normal);
            font-family: var(--montserrat);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }
    }
    .all-posts{
      margin-top:50px;
      a{
        border-radius: 27.5px;
        border: 1px solid var(--foundation-yellow-light);
        gap:15px;
        height:55px;
        width:270px;
        span{
          color: var(--foundation-yellow-light);
          font-family: var(--montserrat);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 135%;
        }
      }
    }
  }
}

footer{
  background: var(--foundation-yellow-light);
  box-shadow: -2px -2px 8px 0 rgba(30, 50, 64, 0.13);
  margin-top:170px;
  .social-media{
    background: var(--foundation-blue-normal, #152835);
    box-shadow: 0 4px 4px 0 rgba(21, 40, 53, 0.15);
    margin-top:-75px;
    position: relative;
    display: inline-block;
    a{
      padding:35px 0;
      display: flex;
      justify-content: center;
    }
    .sm-col{
      position: relative;
      &:last-child{
        &::before{
          display: none;
        }
      }
      &::before{
        content:"";
        position: absolute;
        width:1px;
        height:84px;
        background: var(--foundation-yellow-normal);
        top:0;
        bottom:0;
        right:0;
        margin:auto;
      }
    }
    .social-media-column{
      position: relative;
      ul{
        li{
          &:nth-child(1){
            color: var(--foundation-yellow-normal);
            font-family: var(--montserrat);
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          &:nth-child(2){
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top:10px;
          }
        }
      }
    }
  }
  .footer-logo{
    margin-top:28px;
    margin-bottom:44px;
  }
  .footer-menu{
    ul{
      gap:73px;
      li{
        a{
          color: var(--foundation-blue-normal);
          font-family: var(--montserrat);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }
  }
  .copyright{
    padding:50px 0;
    a{
      gap:6px;
      span{
        color: var(--foundation-blue-normal);
        font-family: var(--montserrat);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
    }
  }
}

section#contact{
  padding-top:190px;
  .left{
    .title{
      color: var(--foundation-blue-normal);
      font-family: var(--ivymode);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .desc{
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      max-width:362px;
      margin-top:40px;
    }
    ul{
      max-width:387px;
      margin-top:64px;
      li{
        color: var(--foundation-blue-normal);
        font-family: var(--montserrat);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        gap:10px;
        margin-bottom:17px;
        &:last-child{
          margin-bottom:0;
        }
        svg{
          width:16px;
        }
      }
      .show-on-map{
        padding-left:30px;
      }
    }
  }
  .right{
    position: relative;
    padding-right:32px;
    .pattern{
      position: absolute;
      right:0;
      top:-176px;
      z-index:11;
    }
    form{
      width: 100%;
      max-width: 824px;
      max-height: 546px;
      height: 100%;
      position: relative;
      z-index: 10;
      &::before{
        content:"";
        background: var(--foundation-yellow-normal);
        width:72px;
        height:311px;
        position: absolute;
        right:-32px;
        top:-70px;
        z-index:-1;
      }
      .form-content{
        background: var(--foundation-blue-normal);
        position: relative;
        z-index: 5;
        height: 100%;
      }
      .contact-form{
        width: 100%;
        padding: 80px;
        .form-col-2{
          display: flex;
          gap: 85px;
          .col{
            flex: 1;
          }
        }
        .form-field{
          position: relative;
          margin-bottom: 40px;
        }
        .input-text{
          display: block;
          width: 100%;
          height: 36px;
          border-width: 0 0 1px 0;
          border-color: var(--foundation-yellow-normal);
          background: transparent;
          box-shadow: none;
          padding: 0;
          color: var(--foundation-yellow-light);
          font-family: var(--montserrat);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          opacity: 0.5;
          &:focus{
            outline: none;
          }
          &:focus + .label{
            transform: translateY(-24px);
          }
        }
        .input-text.not-empty + .label{
          transform: translateY(-24px);
        }
        .label{
          position: absolute;
          left: 0;
          bottom: 11px;
          cursor: text;
          transition: transform 0.2s ease-in-out;
          color: var(--foundation-yellow-light);
          font-family: var(--montserrat);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .checkbox {
          label {
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.28px;
          }
        }
        button{
          border-radius: 27.5px;
          border: 1px solid var(--foundation-yellow-light);
          gap:15px;
          height:55px;
          width:270px;
          span{
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
          }
        }
      }
      input[type="checkbox"]{
        background:transparent;
        border-color:var(--foundation-yellow-normal);
      }
      input[type="checkbox"]:checked{
        background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
        background-size: 80% 80%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--foundation-yellow-normal);
        border-color: var(--foundation-yellow-normal);
      }
    }
  }
}

.checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em;
}
.checkbox label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  width: 1.25em;
  height: 1.25em;
  margin: 0;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: var(--color-dark-yellow);
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.08em solid #4F4F4F;
  box-shadow: none;
  outline: none;
}
input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
}
input[type="checkbox"]:focus {
  box-shadow: none !important;
  outline: none !important;
}

section#aboutPage{
  padding-top:360px;
  &::before{
    content:"";
    background: var(--foundation-blue-normal);
    width:794px;
    height:679px;
    position: absolute;
    right:0;
    top:0;
    z-index:-1;
  }
  .left{
    h1{
      color: var(--foundation-blue-normal);
      font-family: var(--ivymode);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    h2{
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-top:24px;
    }
    .content{
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top:35px;
      strong{
        color: var(--foundation-blue-normal);
        font-family: var(--montserrat);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
      *{
        margin-bottom:20px;
      }
    }
  }
  .right{
    position: relative;
    .pattern{
      position: absolute;
      right:0;
      top:-86px;
    }
  }
}

/* Animations */

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

section#certificates{
  h3{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
  }
  .columns{
    border-top:1px solid var(--foundation-yellow-normal);
    border-bottom:1px solid var(--foundation-yellow-normal);
    margin-top:48px;
    padding:20px 0;
  }
}

section#documents{
  background: linear-gradient(180deg, #11202A 0%, #273B49 48.44%, #11202A 100%);
  padding:50px 0;
  margin-top:111px;
  h3{
    color: var(--foundation-yellow-light);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    margin-bottom:40px;
  }
  .wrapper{
    max-width:890px;
    .column{
      text-align: center;
      img{
        display: inline-block;
      }
      p{
        margin-top:16px;
        color: var(--foundation-yellow-light);
        text-align: center;
        font-family: var(--montserrat);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

section#treatmentsPage{
  padding-top:330px;
  position: relative;
  margin-bottom:152px;
  .pattern{
    position: absolute;
    top:250px;
    right:300px;
  }
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    width: 794px;
    height: 562px;
    z-index:-1;
    right:0;
    top:0;
  }
  h1{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h2{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:448px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position:absolute;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
      bottom:-25px;
      left:0;
    }
  }
}

section#treatmentDetailPage{
  padding-top:360px;
  &::before{
    content:"";
    background: var(--foundation-blue-normal);
    width:794px;
    height:679px;
    position: absolute;
    right:0;
    top:0;
    z-index:-1;
  }
  .left{
    margin-top:121px;
    h1{
      color: var(--foundation-blue-normal);
      font-family: var(--ivymode);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .content{
      margin-top:43px;
      color: var(--foundation-blue-normal);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      p{
        margin-bottom:20px;
      }
    }
  }
  .right{
    position: relative;
    .pattern{
      position: absolute;
      right:0;
      top:-86px;
    }
  }
}

section#treatmentDetailPageContent{
  h3{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.32px;
  }
  .content{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top:30px;
    p{
      margin-bottom:20px;
    }
  }
}

section#blogPage{
  padding-top:330px;
  position: relative;
  margin-bottom:220px;
  .pattern{
    position: absolute;
    top:250px;
    right:300px;
  }
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    width: 794px;
    height: 562px;
    z-index:-1;
    right:0;
    top:0;
  }
  h1{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h2{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:448px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position:absolute;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
      bottom:-25px;
      left:0;
    }
  }
}

section#blogDetailPage{
  position: relative;
  padding-top:275px;
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    right:0;
    top:0;
    width: 794px;
    height: 465px;
    z-index:-1;
  }
  .cover{
    position: relative;
    &::before{
      content:"";
      position: absolute;
      width:100%;
      height:15px;
      background: var(--foundation-yellow-normal);
      inset:0;
      margin:auto;
      z-index:-1;
    }
  }
  h1{
    color: var(--foundation-blue-normal);
    text-align: center;
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:628px;
    position: relative;
    margin-top:20px;
    &::before{
      content:"";
      position: absolute;
      width:185px;
      height:1px;
      background: #152835;
      bottom:-25px;
      left:0;
      right:0;
      margin:auto;
    }
  }
  h2{
    color: var(--foundation-dark);
    font-family: var(--montserrat);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top:80px;
  }
  .wrapper{
    margin-top:20px;
    color: #000;
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom:75px;
    p{
      margin-bottom:20px;
    }
  }
}

section#galleryPage{
  padding-top:330px;
  position: relative;
  margin-bottom:220px;
  .pattern{
    position: absolute;
    top:250px;
    right:300px;
  }
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    width: 794px;
    height: 562px;
    z-index:-1;
    right:0;
    top:0;
  }
  h1{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h2{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:448px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position:absolute;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
      bottom:-25px;
      left:0;
    }
  }
}

section#commentsPage{
  padding-top:330px;
  position: relative;
  margin-bottom:220px;
  .pattern{
    position: absolute;
    top:250px;
    right:300px;
  }
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    width: 794px;
    height: 562px;
    z-index:-1;
    right:0;
    top:0;
  }
  h1{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h2{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:448px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position:absolute;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
      bottom:-25px;
      left:0;
    }
  }
}

section#commentsPageContent{
  .comment-column{
    border-bottom: 10px solid var(--foundation-yellow-normal);
    background: var(--foundation-blue-normal);
    padding:70px 30px;
    position: relative;
    padding-top:120px;
    margin-bottom:50px;
    &::before{
      content:"";
      width:100%;
      height:60px;
      background:#fff;
      position:absolute;
      left:0;
      right:0;
      top:0;
    }
    .desc{
      color: var(--foundation-yellow-light);
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top:20px;
      margin-bottom:32px;
    }
    .comment-footer{
      p{
        color: var(--foundation-yellow-normal);
        font-family: var(--ivymode);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
    .comment-header-block{
      width: 116px;
      height: 116px;
      border:1px solid var(--foundation-blue-normal);
      position: absolute;
      left:0;
      right:0;
      top:0;
      margin:auto;
      border-radius:100%;
      .comment-header{
        background: var(--foundation-yellow-normal);
        filter: drop-shadow(0 4px 4px var(--foundation-dark-blue));
        width: 93px;
        height: 93px;
        border-radius:100%;
        p{
          color: var(--foundation-blue-normal);
          font-family: var(--ivymode);
          font-size: 45px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }
}

section#shareComments{
  .wrapper{
    max-width:935px;
    h3{
      color: var(--foundation-blue-normal);
      font-family: var(--ivymode);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: center;
    }
    h4{
      color: var(--foundation-blue-normal);
      text-align: center;
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.18px;
      margin-top:32px;
      max-width:435px;
    }
    .comment-box{
      border-bottom: 10px solid var(--foundation-yellow-normal);
      background: var(--foundation-blue-normal);
      box-shadow: 0 4px 6px 0 rgba(21, 40, 53, 0.15);
      margin-top:50px;
      form{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;
        #full-stars .rating-group {
          display: inline-flex;
        }
        #full-stars .rating__icon {
          pointer-events: none;
        }
        #full-stars .rating__input {
          position: absolute !important;
          left: -9999px !important;
        }
        #full-stars .rating__input--none {
          display: none;
        }
        #full-stars .rating__label {
          cursor: pointer;
          padding: 0 0.1em;
          font-size: 2rem;
        }
        #full-stars .rating__icon--star {
          color: #96774C;
        }
        #full-stars .rating__input:checked ~ .rating__label .rating__icon--star {
          color: #ddd;
        }
        #full-stars .rating-group:hover .rating__label .rating__icon--star {
          color: #96774C;
        }
        #full-stars .rating__input:hover ~ .rating__label .rating__icon--star {
          color: #ddd;
        }
        .form-content{
          background: var(--foundation-blue-normal);
          position: relative;
          z-index: 5;
          height: 100%;
        }
        .contact-form{
          width: 100%;
          padding:50px 150px;
          .form-col-2{
            display: flex;
            gap: 85px;
            .col{
              flex: 1;
            }
          }
          .form-field{
            position: relative;
            margin-bottom: 40px;
          }
          .input-text{
            display: block;
            width: 100%;
            height: 36px;
            border-width: 0 0 1px 0;
            border-color: var(--foundation-yellow-normal);
            background: transparent;
            box-shadow: none;
            padding: 0;
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            opacity: 0.5;
            &:focus{
              outline: none;
            }
            &:focus + .label{
              transform: translateY(-24px);
            }
          }
          .input-text.not-empty + .label{
            transform: translateY(-24px);
          }
          .label{
            position: absolute;
            left: 0;
            bottom: 11px;
            cursor: text;
            transition: transform 0.2s ease-in-out;
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .checkbox {
            label {
              color: var(--foundation-yellow-light);
              font-family: var(--montserrat);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.28px;
            }
          }
          button{
            border-radius: 27.5px;
            border: 1px solid var(--foundation-yellow-light);
            gap:15px;
            height:55px;
            width:270px;
            span{
              color: var(--foundation-yellow-light);
              font-family: var(--montserrat);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 135%;
            }
          }
        }
        input[type="checkbox"]{
          background:transparent;
          border-color:var(--foundation-yellow-normal);
        }
        input[type="checkbox"]:checked{
          background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
          background-size: 80% 80%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: var(--foundation-yellow-normal);
          border-color: var(--foundation-yellow-normal);
        }
      }
    }
  }
}

section#contactPage{
  padding-top:330px;
  position: relative;
  .pattern{
    position: absolute;
    top:250px;
    right:300px;
  }
  &::before{
    content:"";
    position: absolute;
    background: var(--foundation-blue-normal);
    width: 794px;
    height: 562px;
    z-index:-1;
    right:0;
    top:0;
  }
  h1{
    color: var(--foundation-blue-normal);
    font-family: var(--ivymode);
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  h2{
    color: var(--foundation-blue-normal);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width:448px;
    margin-top:32px;
    position: relative;
    &::before{
      content:"";
      position:absolute;
      background: var(--foundation-blue-normal);
      width:185px;
      height:1px;
      bottom:-25px;
      left:0;
    }
  }
}

section#contactPageContent{
  .left{
    ul{
      margin-top:64px;
      li{
        color: var(--foundation-blue-normal);
        font-family: var(--montserrat);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        gap:10px;
        margin-bottom:17px;
        &:last-child{
          margin-bottom:0;
        }
        svg{
          width:16px;
        }
      }
      .show-on-map{
        padding-left:30px;
      }
    }
  }
  .right{
    position: relative;
    z-index:10;
  }
}

section#contactForm{
  padding-top:68px;
  border-top:14px solid var(--foundation-yellow-normal);
  margin-top:-20px;
  .wrapper{
    max-width:824px;
    h3{
      color: var(--foundation-blue-normal);
      font-family: var(--ivymode);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: center;
    }
    h4{
      color: var(--foundation-blue-normal);
      text-align: center;
      font-family: var(--montserrat);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      max-width:500px;
      margin-top:32px;
    }
    .contact-form-box{
      background: var(--foundation-blue-normal);
      box-shadow: 0 4px 6px 0 rgba(21, 40, 53, 0.15);
      margin-top:50px;
      position: relative;
      .contact-form-pattern{
        position:absolute;
        top:-68px;
        left:-166px;
        z-index:5;
      }
      &::after{
        content:"";
        position:absolute;
        width: 72px;
        height: 311px;
        background: var(--foundation-yellow-normal);
        bottom:-46px;
        right:-36px;
      }
      form{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;
        .form-content{
          background: var(--foundation-blue-normal);
          position: relative;
          z-index: 5;
          height: 100%;
        }
        .contact-form{
          width: 100%;
          padding:50px 95px;
          .form-col-2{
            display: flex;
            gap: 85px;
            .col{
              flex: 1;
            }
          }
          .form-field{
            position: relative;
            margin-bottom: 40px;
          }
          .input-text{
            display: block;
            width: 100%;
            height: 36px;
            border-width: 0 0 1px 0;
            border-color: var(--foundation-yellow-normal);
            background: transparent;
            box-shadow: none;
            padding: 0;
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            opacity: 0.5;
            &:focus{
              outline: none;
            }
            &:focus + .label{
              transform: translateY(-24px);
            }
          }
          .input-text.not-empty + .label{
            transform: translateY(-24px);
          }
          .label{
            position: absolute;
            left: 0;
            bottom: 11px;
            cursor: text;
            transition: transform 0.2s ease-in-out;
            color: var(--foundation-yellow-light);
            font-family: var(--montserrat);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .checkbox {
            label {
              color: var(--foundation-yellow-light);
              font-family: var(--montserrat);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.28px;
            }
          }
          button{
            border-radius: 27.5px;
            border: 1px solid var(--foundation-yellow-light);
            gap:15px;
            height:55px;
            width:270px;
            span{
              color: var(--foundation-yellow-light);
              font-family: var(--montserrat);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 135%;
            }
          }
        }
        input[type="checkbox"]{
          background:transparent;
          border-color:var(--foundation-yellow-normal);
        }
        input[type="checkbox"]:checked{
          background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
          background-size: 80% 80%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: var(--foundation-yellow-normal);
          border-color: var(--foundation-yellow-normal);
        }
      }
    }
  }
}

@media only screen and (max-width: 2560px) {

  section#slider {
    &::after{
      max-width:1150px;
    }
    .wrapper{
      .online-appointment{
        bottom:350px;
      }
      .slider-pattern{
        bottom:200px;
      }
    }
  }

}

@media only screen and (max-width: 1920px) {

  section#slider {
    &::after{
      max-width:794px;
    }
    .wrapper{
      .online-appointment{
        bottom:95px;
      }
      .slider-pattern{
        bottom:0;
      }
    }
  }

}

@media only screen and (max-width: 1600px) {

  section#slider {
    &::after{
      max-width:700px;
    }
  }

  section#aboutPage{
    &::before{
      width:40%;
      height:470px;
    }
  }

  section#treatmentDetailPage{
    &::before{
      width:40%;
      height:470px;
    }
  }

}

@media only screen and (max-width: 1440px) {

  section#slider {
    &::after{
      max-width:550px;
    }
    .wrapper{
      .online-appointment{
        bottom:95px;
      }
      .slider-pattern{
        bottom:0;
      }
    }
  }

  section#commentsPage{
    &::before{
      width:40%;
    }
    .pattern{
      right:170px;
    }
  }

  section#blogPage{
    &::before{
      width:40%;
    }
    .pattern{
      right:170px;
    }
  }

  section#blogDetailPage{
    &::before{
      width:40%;
    }
  }

  section#contactPage{
    &::before{
      width:40%;
    }
    .pattern{
      right:170px;
    }
  }

}


@media only screen and (max-width: 1280px) {

  .container{
    max-width:90%;
  }

  section#slider {
    .slider-title, .slider-desc{
      left:5% !important;
    }
    .slider-title{
      font-size:35px;
    }
    .slider-desc{
      top:430px !important;
    }
    .slider-btn{
      margin-left:60px;
    }
  }

  header{
    padding-top:40px;
    .logo{
      max-width:180px;
    }
  }

  section#about {
    h3{
      font-size:35px;
    }
  }

  section#treatments {
    h3{
      font-size:35px;
    }
    .wrapper{
      .treatment-column{
        .caption{
          p{
            font-size:30px;
          }
        }
      }
    }
  }

  section#marquee {
    .marquee {
      ul {
        li {
          a{
            font-size:30px;
          }
        }
      }
    }
  }

  section#blog {
    h3{
      font-size:35px;
    }
    .wrapper{
      .blog-column{
        .content{
          .title{
            font-size:20px;
          }
        }
      }
    }
  }

  section#contact {
    .left {
      .title{
        font-size:35px;
      }
    }
  }

  section#aboutPage{
    padding-top:240px;
    .left{
      h1{
        font-size:35px;
      }
    }
  }

  section#certificates {
    h3{
      font-size:35px;
    }
  }

  section#documents {
    margin-top:50px;
    h3{
      font-size:35px;
    }
    .wrapper{
      .column{
        p{
          font-size:20px;
        }
      }
    }
  }

  section#treatmentsPage {
    padding-top:300px;
    &::before{
      width:55%;
    }
    h1{
      font-size:35px;
    }
  }

  section#treatmentDetailPage{
    padding-top:250px;
    &::before{
      width:40%;
    }
    .left{
      h1{
        font-size:35px;
      }
    }
    .right{
      .pattern{
        img{
          max-width:150px;
        }
      }
    }
  }

  section#treatmentDetailPageContent {
    h3{
      font-size:25px;
    }
  }

  section#commentsPage {
    h1{
      font-size:35px;
    }
    h2{
      margin-top:15px;
    }
    .pattern{
      right:150px;
    }
  }

  section#shareComments {
    .wrapper {
      h3{
        font-size:35px;
      }
      h4{
        margin-top:15px;
      }
    }
  }

  section#blogPage{
    h1{
      font-size:35px;
    }
    h2{
      font-size:16px;
      margin-top:10px;
    }
  }

  section#blogDetailPage {
    h1{
      font-size:35px;
    }
    h2{
      font-size:20px;
    }
  }

  section#contactPage{
    padding-top:250px;
    h1{
      font-size:35px;
    }
  }

}

@media only screen and (max-width: 1024px) {

  section#slider {
    &::after{
      max-width:420px;
    }
    .wrapper{
      .online-appointment{
        right:375px;
      }
    }
    .slider-desc{
      top:330px !important;
      max-width:400px;
    }
    .slider-btn{
      span{
        font-size:16px;
      }
    }
  }

  section#about {
    h3{
      font-size:30px;
    }
    h4{
      font-size:18px;
      margin-top:10px;
    }
    .about-btn{
      span{
        font-size:16px;
      }
    }
  }

  section#treatments {
    h3{
      font-size:30px;
    }
    .wrapper{
      .treatment-column{
        .caption{
          p{
            font-size:25px;
          }
        }
      }
    }
    .treatment-pattern{
      img{
        max-width:100px;
      }
    }
  }

  section#marquee {
    .marquee {
      ul {
        li {
          a{
            font-size:25px;
          }
        }
      }
    }
  }

  section#blog {
    h3{
      font-size:30px;
    }
    .wrapper{
      .all-posts{
        a{
          span{
            font-size:16px;
          }
        }
      }
    }
  }

  footer {
    margin-top:100px;
    .social-media {
      .social-media-column {
        ul {
          li {
            &:nth-child(1){
              font-size:18px;
            }
            &:nth-child(2){
              margin-top:5px;
            }
          }
        }
      }
    }
    .footer-logo{
      img{
        max-width:150px;
      }
    }
    .footer-menu{
      ul{
        li{
          a{
            font-size:16px;
          }
        }
      }
    }
  }

  section#contact {
    padding-top:50px;
    .left {
      ul {
        margin-top:20px;
        li{
          font-size:16px;
        }
      }
      .title{
        font-size:30px;
      }
      .desc{
        margin-top:20px;
        font-size:16px;
      }
    }
    .right{
      form{
        &::before{
          top:-20px;
        }
        .contact-form{
          padding:50px;
          button{
            span{
              font-size:16px;
            }
          }
        }
      }
      .pattern{
        top:-50px;
        img{
          max-width:100px;
        }
      }
    }
  }

  section#aboutPage {
    padding-top:200px;
    .left {
      h1{
        font-size:30px;
      }
      h2{
        font-size:18px;
        margin-top:10px;
      }
      .content{
        font-size:16px;
        strong{
          font-size:16px;
        }
      }
    }
    .right{
      .pattern{
        top:-40px;
        img{
          max-width:100px;
        }
      }
    }
  }

  section#certificates {
    h3{
      font-size:30px;
    }
    .columns{
      margin-top:30px;
    }
  }

  section#documents {
    h3{
      font-size:30px;
    }
    .wrapper{
      .column{
        p{
          font-size:18px;
        }
      }
    }
  }

  .overlay {
    ul {
      li {
        min-height:30px;
        a{
          font-size:30px;
        }
      }
    }
    .right{
      bottom:100px;
      img{
        max-width:150px;
      }
    }
  }

  section#treatmentsPage{
    margin-bottom:200px;
    &::before{
      width:50%;
    }
    .pattern{
      right:140px;
    }
    h1{
      font-size:30px;
    }
    h2{
      font-size:16px;
      margin-top:20px;
    }
  }

  section#treatmentDetailPage {
    padding-top:200px;
    .left {
      margin-top:50px;
      h1{
        font-size:30px;
      }
      .content{
        font-size:16px;
        margin-top:20px;
      }
    }
  }

  section#treatmentDetailPageContent {
    h3{
      font-size:20px;
    }
  }

  section#commentsPage {
    h1{
      font-size:30px;
    }
    h2{
      font-size:16px;
    }
    .pattern{
      right:100px;
    }
  }

  section#shareComments {
    .wrapper {
      h3{
        font-size:30px;
      }
      h4{
        font-size:16px;
      }
    }
  }

  section#blogDetailPage {
    h1{
      font-size:30px;
    }
    h2{
      font-size:20px;
      margin-top:50px;
    }
  }

  section#contactPage {
    h1{
      font-size:30px;
    }
    .pattern{
      right:100px;
    }
    h2{
      font-size:16px;
      margin-top:10px;
    }
  }

  section#contactForm{
    margin-top:50px;
    .wrapper{
      h3{
        font-size:30px;
      }
      h4{
        font-size:16px;
        margin-top:10px;
      }
    }
  }

}

@media only screen and (max-width: 600px) {

  body,html{
    overflow-x:hidden;
  }

  body.active {
    header.active {
      &::before{
        background:var(--foundation-white-light);
      }
    }
  }

  section#slider{
    height:38.6vh;
    &::after{
      display:none;
    }
    .slider-cover{
      width:100% !important;
      left:0 !important;
      top:0 !important;
      height:auto !important;
    }
    .slider-pattern{
      display: none;
    }
    .wrapper{
      height:50vh;
      .online-appointment{
        left:0;
        right:0;
        margin:auto;
        animation: unset;
        display:none;
      }
    }
  }

  section#about {
    margin-top:20px;
    .left{
      .cover{
        width:100%;
      }
    }
    .about-pattern{
      display:none;
    }
    .content{
      margin-top:10px;
      font-size:16px;
      gap:10px;
    }
    .about-btn{
      width:100%;
      margin-top:20px;
    }
    .right-content{
      margin-top:0;
    }
    h3{
      font-size:24px;
    }
    h4{
      font-size:16px;
    }
  }

  section#treatments {
    margin-top:20px;
    &::before{
      bottom:-30px;
    }
    h3{
      text-align:left;
      font-size:24px;
    }
    h4{
      text-align:left;
      margin-top:20px;
      font-size:16px;
      &::before{
        right:auto;
      }
    }
    .wrapper{
      max-width:100%;
      margin-top:35px;
      .treatment-column{
        .caption{
          p{
            font-size:20px;
          }
        }
      }
    }
    .treatment-pattern{
      display:none;
    }
  }

  section#marquee{
    margin-top:50px;
    .marquee{
      ul{
        li{
          a{
            font-size:20px;
          }
        }
      }
    }
  }

  section#blog{
    margin-top:20px;
    h3{
      text-align:left;
      font-size:24px;
    }
    h4{
      text-align:left;
      max-width:100%;
      font-size:16px;
    }
    .wrapper{
      margin-top:10px;
      .all-posts{
        margin-top:20px;
        a{
          width:100%;
        }
      }
      .blog-column{
        .content{
          .title{
            font-size:18px;
          }
          .desc{
            font-size:16px;
          }
          .more-btn{
            span{
              font-size:16px;
            }
          }
        }
      }
    }
  }

  section#contact{
    padding-top:0;
    .left{
      ul{
        max-width:100%;
        margin-top:20px;
      }
      .title{
        font-size:24px;
      }
      .desc{
        font-size:16px;
        margin-top:10px;
      }
    }
    .right{
      padding-right:0;
      .pattern{
        display:none;
      }
      form{
        &::before{
          display:none;
        }
        .contact-form{
          padding:30px;
          .form-col-2{
            display: block;
          }
        }
      }
    }
  }

  footer {
    margin-top:200px;
    .footer-menu {
      ul{
        gap:0;
        li{
          width:100%;
          text-align:center;
          a{
            text-align:center;
          }
        }
      }
    }
    .social-media{
      margin-top:20px;
      a{
        padding:0;
        justify-content: left;
      }
    }
  }

  .overlay{
    &::after{
      background: var(--foundation-white-light);
    }
    ul{
      margin-top:0;
      li{
        min-height:22px;
        a{
          font-size:22px;
        }
      }
    }
  }

  header{
    padding-top:20px;
    .logo{
      max-width:100px;
    }
  }

  section#aboutPage {
    padding-top:100px;
    .flex-wrap{
      flex-wrap: wrap-reverse;
    }
    &::before{
      display:none;
    }
    .left{
      margin-top:-20px;
      h1{
        font-size:24px;
      }
      h2{
        font-size:16px;
      }
      .content{
        margin-top:10px;
      }
    }
  }

  section#certificates{
    h3{
      font-size:24px;
    }
    .columns{
      .column{
        width:50%;
        padding-left:.7rem;
        padding-right:.7rem;
        margin-top: .7rem;
        margin-bottom: .7rem;
      }
    }
  }

  section#documents {
    padding:30px 0;
    h3{
      font-size:24px;
      margin-bottom:20px;
    }
    .wrapper{
      .doc-column{
        width:50%;
        padding-left:.7rem;
        padding-right:.7rem;
        margin-top: .7rem;
        margin-bottom: .7rem;
      }
      .column{
        p{
          font-size:16px;
        }
      }
    }
  }

  section#treatmentsPage{
    padding-top:150px;
    margin-bottom:0;
    &::before{
      display:none;
    }
    .pattern{
      display:none;
    }
    h1{
      font-size:24px;
    }
    h2{
      margin-top:10px;
    }
  }

  section#treatmentDetailPage {
    padding-top:100px;
    &::before{
      display:none;
    }
    .left{
      margin-top:-20px;
      h1{
        font-size:24px;
      }
    }
    .flex-wrap{
      flex-wrap:wrap-reverse;
    }
    .right{
      .pattern{
        top:-40px;
        img{
          max-width:100px;
        }
      }
    }
  }

  section#treatmentDetailPageContent {
    h3{
      font-size:18px;
    }
    .px-5 {
      padding-left: .7rem;
      padding-right: .7rem;
    }
    .my-5 {
      margin-top: .7rem;
      margin-bottom: .7rem;
    }
    .content{
      margin-top:10px;
      *{
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }

  section#commentsPage{
    padding-top:120px;
    margin-bottom:100px;
    &::before{
      display:none;
    }
    .pattern{
      display:none;
    }
    h1{
      font-size:24px;
    }
    h2{
      margin-top:10px;
    }
  }

  section#commentsPageContent {
    .comment-column {
      .desc{
        font-size:16px;
      }
      .comment-footer{
        p{
          font-size:16px;
        }
      }
    }
  }

  section#shareComments {
    .wrapper{
      h3{
        font-size:24px;
      }
      h4{
        margin-top:10px;
      }
      .comment-box{
        form{
          .contact-form{
            padding:25px;
            .form-col-2{
              display:block;
            }
            button{
              span{
                font-size:16px;
              }
            }
          }
        }
      }
    }
  }

  section#blogPage{
    padding-top:120px;
    margin-bottom:70px;
    &::before{
      display:none;
    }
    .pattern{
      display:none;
    }
    h1{
      font-size:24px;
    }
  }

  section#blogDetailPage{
    padding-top:100px;
    &::before{
      display:none;
    }
    h1{
      font-size:24px;
    }
    h2{
      font-size:18px;
    }
    .wrapper{
      font-size:16px;
    }
  }

  section#contactPage{
    padding-top:120px;
    &::before{
      display:none;
    }
    .pattern{
      display:none;
    }
    h1{
      font-size:24px;
    }
  }

  section#contactPageContent {
    .left {
      ul {
        margin-top:25px;
        li{
          font-size:16px;
        }
      }
    }
  }

  section#contactForm{
    margin-top:25px;
    padding-top:25px;
    .wrapper{
      h3{
        font-size:24px;
      }
      .contact-form-box{
        margin-top:25px;
        &::after{
          display:none;
        }
        .contact-form-pattern{
          display:none;
        }
        form{
          .contact-form{
            padding:50px 25px;
            .form-col-2{
              display:block;
            }
          }
        }
      }
    }
  }

  .masonry-container {
    column-count:1;
    column-gap: 40px;
  }

}

/* Effects */

.img-container {
  position: relative;
  overflow: hidden;
  width:100%;
  max-width:535px;
  img {
    width: 100%;
    opacity: 0;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    background: var(--foundation-yellow-normal);
  }
}

.large-text-animation{
  overflow:hidden;
}
